
<template>
  <div>
    <el-tabs>
      <el-tab-pane label="基本信息">
        <el-form ref="baseform" class="mt20" v-model="adminInfo" label-width="150px">
          <el-form-item label="角色类型：" required>
            <el-select v-model="adminInfo.roleId" placeholder="请选择" :disabled="adminInfo.roleId==1" >
              <el-option
                v-for="item in roleOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.value==1"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="所属活动：">
            <el-input v-model="adminInfo.actName"></el-input>
          </el-form-item>-->
          <el-form-item label="用户名" required>
            <el-input v-model="adminInfo.name" maxlength="12" :disabled="adminId!=0"></el-input>
          </el-form-item>
          <el-form-item label="中文名：" required>
            <el-input v-model="adminInfo.nameInChinese" minlength="12"></el-input>
          </el-form-item>
          <el-form-item label="手机：" required>
            <el-input v-model.number="adminInfo.mobile" :disabled="adminId!=0"></el-input>
          </el-form-item>
          <el-form-item label="邮箱：" required>
            <el-input v-model="adminInfo.email" :disabled="adminId!=0"></el-input>
          </el-form-item>
          <el-form-item label="密码：" :required="!adminId" maxlength="30">
            <el-input v-model="adminInfo.password" v-if="!adminId"></el-input>
            <el-input v-model="password" v-else placeholder="不填则不修改密码"></el-input>
            <span class="ml10">编辑时不输入密码则不修改，输入则为修改新密码。</span>
          </el-form-item>
          <el-form-item label="状态：" required>
            <el-switch
              v-model="adminInfo.status"
              active-color="#13ce66"
              active-text="启用"
              active-value="active"
              inactive-color="#ff4949"
              inactive-text="禁用"
              inactive-value="forbidden"
            ></el-switch>
          </el-form-item>
          <el-form-item>
            <el-button @click="submitInfo" v-if="adminId" style="width: 195px;" type="primary">确定修改</el-button>
            <el-button @click="createInfo" v-else style="width: 195px;" type="primary">确定添加</el-button>
            <el-button @click="toActList" style="width: 195px;" class="ml10" type="primary">返 回</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>


<script>
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
export default {
  name: "adminInfo",
  data() {
    return {
      adminId: 0,
      roleOptions: [
        { value: 1, label: "超级管理员" },//需要问清楚是传什么value
        { value: 2, label: "活动管理员" },
      ],
      adminInfo: {
        actId: 0,
        roleId: 2,
        actId: "",
        name: "",
        nameInChinese: "",
        mobile: "",
        email: "",
        password: "",
        status: "",
      },
      password: "",
      activityID:0,
    };
  },
  methods: {
    async submitInfo() {
      try {
        let act = JSON.parse(JSON.stringify(this.adminInfo));
        console.log(act);
        if (!act.name) {
          this.$message.warning("请输入用户名");
          return;
        }
        if (act.name.length < 3 || act.name.length > 12) {
          this.$message.warning("用户名长度必须大于3为且小于12位");
          return;
        }
        if (!act.nameInChinese) {
          this.$message.warning("请输入中文名");
          return;
        }
        if (!this.isPhone(act.mobile)) {
          this.$message.warning("请输入正确的手机");
          return;
        }
        if (!act.email) {
          this.$message.warning("请输入邮箱");
          return;
        }
        // if (this.adminId == 0 && !act.password) {
        //   this.$message.warning("请输入密码0");
        //   return;
        // }
        // if (this.adminId != 0 && this.password !== "") {
        //   this.$message.warning("请输入密码1");
        //   return;
        // }
        // if (
        //   (this.adminId == 0 && act.password.length < 6) ||
        //   act.password.length > 30
        // ) {
        //   this.$message.warning("密码长度至少6位为且小于30位0");
        //   return;
        // }
        if (
          (this.adminId != 0 &&
            this.password !== "" &&
            this.password.length < 6) ||
          this.password.length > 30
        ) {
          this.$message.warning("密码长度至少6位为且小于30位1");
          return;
        }

        if (this.adminId) {
          act.password = this.password;
        }
        act.actId = Number(this.activityID);
        await ActivityAjax.updateAdminDetail(act);
        this.$message.success("保存成功");
        this.toActList();
      } catch (error) {
        this.$message.error(error);
      }
    },
    async createInfo() {
      try {
        let act = JSON.parse(JSON.stringify(this.adminInfo));
        act.actId = Number(this.activityID);
        console.log(act);
        if (!this.isPhone(act.mobile)) {
          this.$message.warning("请输入正确的手机格式");
          return;
        }
        await ActivityAjax.createAdminDetail(act);
        this.$message.success("保存成功");
        this.toActList();
      } catch (error) {
        this.$message.error(error);
      }
    },
    toActList() {
      this.$router.push(`/activity/userset/${this.activityID}`);
      
    },
    isPhone(phone) {
      var bValidate = RegExp(
        /^(0|86|17951)?(13[0-9]|15[012356789]|18[0-9]|17[0-9]|19[0-9]|14[57])[0-9]{8}$/
      ).test(phone);
      if (bValidate) {
        return true;
      } else {
        return false;
      }
    },
    // 获取
    async getAdminDetail() {
      try {
        const data = await ActivityAjax.getAdminDetail(this.adminId);
        console.log(data.data);
        if (data.data) {
          this.adminInfo = data.data;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.adminId = this.$route.query.id || 0;
    if (this.adminId) {
      this.getAdminDetail();
    }
  },
  mounted() {
    this.activityID = this.$route.params.activityID;
  },
};
</script>

<style scoped>
.el-input {
  width: 400px;
}
</style>
