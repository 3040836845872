var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息" } },
            [
              _c(
                "el-form",
                {
                  ref: "baseform",
                  staticClass: "mt20",
                  attrs: { "label-width": "150px" },
                  model: {
                    value: _vm.adminInfo,
                    callback: function($$v) {
                      _vm.adminInfo = $$v
                    },
                    expression: "adminInfo"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色类型：", required: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            disabled: _vm.adminInfo.roleId == 1
                          },
                          model: {
                            value: _vm.adminInfo.roleId,
                            callback: function($$v) {
                              _vm.$set(_vm.adminInfo, "roleId", $$v)
                            },
                            expression: "adminInfo.roleId"
                          }
                        },
                        _vm._l(_vm.roleOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: {
                              label: item.label,
                              value: item.value,
                              disabled: item.value == 1
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户名", required: "" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "12", disabled: _vm.adminId != 0 },
                        model: {
                          value: _vm.adminInfo.name,
                          callback: function($$v) {
                            _vm.$set(_vm.adminInfo, "name", $$v)
                          },
                          expression: "adminInfo.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "中文名：", required: "" } },
                    [
                      _c("el-input", {
                        attrs: { minlength: "12" },
                        model: {
                          value: _vm.adminInfo.nameInChinese,
                          callback: function($$v) {
                            _vm.$set(_vm.adminInfo, "nameInChinese", $$v)
                          },
                          expression: "adminInfo.nameInChinese"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机：", required: "" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.adminId != 0 },
                        model: {
                          value: _vm.adminInfo.mobile,
                          callback: function($$v) {
                            _vm.$set(_vm.adminInfo, "mobile", _vm._n($$v))
                          },
                          expression: "adminInfo.mobile"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮箱：", required: "" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.adminId != 0 },
                        model: {
                          value: _vm.adminInfo.email,
                          callback: function($$v) {
                            _vm.$set(_vm.adminInfo, "email", $$v)
                          },
                          expression: "adminInfo.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "密码：",
                        required: !_vm.adminId,
                        maxlength: "30"
                      }
                    },
                    [
                      !_vm.adminId
                        ? _c("el-input", {
                            model: {
                              value: _vm.adminInfo.password,
                              callback: function($$v) {
                                _vm.$set(_vm.adminInfo, "password", $$v)
                              },
                              expression: "adminInfo.password"
                            }
                          })
                        : _c("el-input", {
                            attrs: { placeholder: "不填则不修改密码" },
                            model: {
                              value: _vm.password,
                              callback: function($$v) {
                                _vm.password = $$v
                              },
                              expression: "password"
                            }
                          }),
                      _c("span", { staticClass: "ml10" }, [
                        _vm._v("编辑时不输入密码则不修改，输入则为修改新密码。")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态：", required: "" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "active-text": "启用",
                          "active-value": "active",
                          "inactive-color": "#ff4949",
                          "inactive-text": "禁用",
                          "inactive-value": "forbidden"
                        },
                        model: {
                          value: _vm.adminInfo.status,
                          callback: function($$v) {
                            _vm.$set(_vm.adminInfo, "status", $$v)
                          },
                          expression: "adminInfo.status"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _vm.adminId
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { width: "195px" },
                              attrs: { type: "primary" },
                              on: { click: _vm.submitInfo }
                            },
                            [_vm._v("确定修改")]
                          )
                        : _c(
                            "el-button",
                            {
                              staticStyle: { width: "195px" },
                              attrs: { type: "primary" },
                              on: { click: _vm.createInfo }
                            },
                            [_vm._v("确定添加")]
                          ),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml10",
                          staticStyle: { width: "195px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.toActList }
                        },
                        [_vm._v("返 回")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }